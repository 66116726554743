import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AfterViewInit, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { HelperService, WindowWrapper } from '@depot/@common';
import { PriceBookRepositoryService } from '@depot/@data';
import { IClientError, IPriceBookLine } from '@depot/custom';
import { environment } from '@env';

import { BehaviorSubject, catchError, finalize, fromEvent, of } from 'rxjs';

@Component({
  selector: 'depot-price-book-line-edit',
  templateUrl: './price-book-line-edit.component.html',
  styleUrls: ['./price-book-line-edit.component.scss']
})
export class PriceBookLineEditComponent implements OnInit, AfterViewInit {
  public imagePath = environment.get_endpoint('pricebook/page/');
  public pdfId = '';
  public form: UntypedFormGroup;
  public globalErrors$ = new BehaviorSubject<string[]>([]);

  private returnUrl = '/price-book/line/';
  private partLine = '';
  private manufacturerNumber = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private priceBookRepo: PriceBookRepositoryService,
    private helper: HelperService,
    private window: WindowWrapper,
    private fb: UntypedFormBuilder,
    private router: Router,
    private destroyRef: DestroyRef,
  ) {

    this.helper.IsGlobalBar$.set(true);

  }

  ngOnInit() {
    const params = this.activatedRoute.snapshot.params;
    const id = params['id'];
    const priceBookLine = this.window.history.state.priceBookLine;
    if (id === '0' && priceBookLine) {
      this.loadPdf(priceBookLine.priceBookPagePartial?.name);
      this.partLine = priceBookLine.partLine;
      this.manufacturerNumber = priceBookLine.manufacturerNumberAlpha;
      this.initializeForm(priceBookLine);
      setTimeout(() => {
        this.helper.IsGlobalBar$.set(false);

      }, 0);
      return;
    }

    this.priceBookRepo.getPriceBookLineById(id)
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.status === HttpStatusCode.NotFound) {
          this.helper.showMessage('Couldn\'t get price book page associated to this price book line', 'error');
        }
        this.helper.mapErrors(err, null, null, null);
        return of(null);
      }),
        finalize(() => this.helper.IsGlobalBar$.set(false))
      )
      .subscribe((pricebookLine: IPriceBookLine) => {
        if (!pricebookLine) {
          this.helper.showMessage(`Could not find a price book line with the id of '${id}'`);
          return;
        }
        this.loadPdf(pricebookLine.priceBookPagePartial?.name);
        this.partLine = pricebookLine.partLine;
        this.manufacturerNumber = pricebookLine.manufacturerNumberAlpha;
        this.initializeForm(pricebookLine);

      });

    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          // Perform actions
          return this.determineNavigation();
        }
      });

  }
  private initializeForm(priceBook: IPriceBookLine) {
    this.form = this.fb.group({
      id: [priceBook.id],
      partLine: [priceBook.partLine],
      manufacturerNumber: [priceBook.manufacturerNumber],
      description: [priceBook.description],
      dealerPrice: [priceBook.dealerPrice],
      listPrice: [priceBook.listPrice],
      make: [priceBook.make],
      date: [priceBook.date],
      lineType: [priceBook.lineType],
      priceBookPageId: [priceBook.priceBookPageId],
    });

  }


  ngAfterViewInit() {
    fromEvent(this.window, 'beforeunload')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x: BeforeUnloadEvent) => {
        if (this.form && this.form.dirty) {
          x.returnValue = 'You have pending changes';
        }
        return x;
      });

  }

  public async delete() {
    const isConfirmed = await this.helper.confirmDialog('Are you sure you want to delete this row?');
    if (isConfirmed) {
      this.priceBookRepo.deletePriceBookLineById(this.form.getRawValue().id)
        .pipe(
          finalize(() => this.helper.IsGlobalSpinner$.set(false))
        )
        .subscribe(x => {
          this.helper.showMessage('Row deleted', 'success');
          this.determineNavigation();
        });
    }
  }

  private determineNavigation() {
    this.priceBookRepo.getPriceBookLine(this.partLine, this.manufacturerNumber, false, false, true)
      .pipe(catchError(x => of([])))
      .subscribe(async priceBookLine => {
        if (priceBookLine.length === 0) {
          await this.router.navigate([this.returnUrl]);

        } else {
          await this.router.navigate([this.returnUrl, this.partLine, this.manufacturerNumber]);
        }
      });

  }

  public async save() {
    this.globalErrors$.next([]);
    if (!this.helper.validateForm(this.form)) {
      this.helper.showMessage('Form is invalid');
      return;
    }
    this.helper.IsGlobalSpinner$.set(true);
    const input = this.form.getRawValue();

    this.priceBookRepo.savePriceBookLine(input)
      .pipe(
        catchError((error: IClientError) => {
          this.helper.mapErrors(error, null, this.form, this.globalErrors$);
          this.helper.logger.error('Error saving part', error);
          return of();
        }),
        finalize(() => this.helper.IsGlobalSpinner$.set(false))
      )
      .subscribe(x => {
        this.form.patchValue({ id: x.id });
        this.router.navigate(['/price-book/line/', x.id], { replaceUrl: true });

        // this.location.go();
        this.form.markAsPristine();
        this.helper.showMessage('Changes saved successful', 'success');

      });

  }

  public loadPdf(pageName: string) {
    if (!pageName) {
      this.pdfId = '';
      return;
    }
    this.pdfId = this.imagePath + pageName + '#view=FitH&toolbar=0';

    return false;
  }

}
