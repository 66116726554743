  <div [formGroup]="form"
       *ngIf="form"
       class="tw-flex tw-flex-col">

    <div class="content tw-flex tw-flex-row tw-flex-wrap tw-gap-2">
      <div class="dp-sticky-item">
        <div class="global-errors">
          <mat-error *ngFor="let err of globalErrors$ | async">&bull; {{err}}</mat-error>
        </div>
      </div>
      <br />
      <mat-form-field class="readonly">
        <mat-label>Part Line</mat-label>
        <input matInput
               formControlName="partLine"
               readonly
               type="text" />
        <mat-error [depot-form-error]="form"
                   errorControlName="partLine">
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Manufacturer Number</mat-label>
        <input matInput
               formControlName="manufacturerNumber"
               type="text"
               required />
        <mat-error [depot-form-error]="form"
                   errorControlName="manufacturerNumber">
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput
               formControlName="description"
               type="text" />
        <mat-error [depot-form-error]="form"
                   errorControlName="description">
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Dealer Price</mat-label>
        <input matInput
               formControlName="dealerPrice"
               depotDecimal
               min="0"
               type="number" />
        <mat-error [depot-form-error]="form"
                   errorControlName="dealerPrice">
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>List Price</mat-label>
        <input matInput
               formControlName="listPrice"
               depotDecimal
               min="0"
               type="number" />
        <mat-error [depot-form-error]="form"
                   errorControlName="listPrice">
        </mat-error>
      </mat-form-field>
    </div>

    <iframe [src]="pdfId | safe:'url'"
            type="application/pdf"
            style="flex-grow: 1;"></iframe>
  </div>

  <div class="dp-top-fab-container">

    <button type="button"
            (click)="save()"
            mat-mini-fab
            matTooltip="Save row"
            matTooltipPosition="below"
            color="accent">
      <span class="fa fa-save"></span>
    </button>

    <button type="button"
            (click)="delete()"
            mat-mini-fab
            matTooltip="Delete row"
            matTooltipPosition="below"
            color="accent">
      <span class="fa fa-trash-alt"></span>
    </button>
  </div>
