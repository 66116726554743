import { DataSource } from '@angular/cdk/table';

import { IPartsSearch, PriceBookRepositoryService } from '@depot/@data';
import { IPagedResults, IPriceBookLine } from '@depot/custom';

import { BehaviorSubject, finalize, lastValueFrom, Subject } from 'rxjs';

export class PriceBookLineGridDataSource extends DataSource<IPriceBookLine> {
  public isLoading$ = new BehaviorSubject(false);
  public totalRows$ = new BehaviorSubject(0);
  public get filterName() { return 'price_book_line_grid'; }
  public columns = [
    'edit',
    'partLine',
    'make',
    'lineType',
    'manufacturerNumber',
    'description',
    // 'listPrice',
    'date',
    'dealerPrice',
  ];
  private data$ = new Subject<IPriceBookLine[]>();
  constructor(private priceBookRepo: PriceBookRepositoryService) {
    super();

  }
  connect() {
    return this.data$;
  }

  public loadData(filters: IPartsSearch) {

    this.isLoading$.next(true);
    this.priceBookRepo.searchPriceBookLines(filters)
      .pipe(
        // catchError(err => of({ items: [] })),
        finalize(() => this.isLoading$.next(false))
      ).subscribe(async (x: IPagedResults<IPriceBookLine>) => {
        for (let i = 0; i < x.items.length; i++) {
          try {
            const pb = await lastValueFrom(this.priceBookRepo.getCurrentPriceBook(x.items[i].lineType));
            if (pb) {
              x.items[i].isCurrent = x.items[i].date === pb.date && x.items[i].lineType === pb.lineType;
            }
          } catch (err) { }
        }
        this.data$.next(x.items);
        this.totalRows$.next(x.totalCount);
      });
  }

  disconnect(): void {
    this.data$.complete();
    this.isLoading$.next(false);
    this.isLoading$.complete();
    this.totalRows$.complete();
    // this.depotContext.close();
  }

}
