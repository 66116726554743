import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { RoleGuard } from '@depot/@common';
import { DepotCommonModule } from '@depot/@common/depot-common.module';
import { DepotComponentsModule } from '@depot/@components/depot-components.module';
import { PriceBookLineEditComponent, PriceBookLineGridComponent, PriceBookLineViewComponent } from '@depot/price-book/line';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DepotCommonModule,
    DepotComponentsModule,
    RouterModule.forChild([{
      path: '',
      component: PriceBookLineGridComponent,
      canActivate: [RoleGuard],
      data: {
        isNav: false,
        text: 'Price Book',
        icon: 'fa fa-money-check-alt',
        roles: [RoleGuard.Admin]
      },

    },
    {
      path: ':id',
      component: PriceBookLineEditComponent,
      canActivate: [RoleGuard],
      data: {
        isNav: false,
        title: 'Price Book > Edit',
        icon: 'fa fa-money-check-alt',
        roles: [RoleGuard.PriceBookEditAdmin]
      }
    },
    {
      path: ':line/:manufacturerNumber',
      component: PriceBookLineViewComponent,
      canActivate: [RoleGuard],
      data: {
        isNav: false,
        title: 'Price Book > View',
        icon: 'fa fa-money-check-alt',
        roles: [RoleGuard.Admin]
      },
    },
    ])
  ],
  declarations: [
    PriceBookLineEditComponent,
    PriceBookLineGridComponent,
    PriceBookLineViewComponent,
  ],
  exports: [PriceBookLineEditComponent, PriceBookLineGridComponent, PriceBookLineViewComponent]
})
export class PriceBookLineModule { }
