import { Component, DestroyRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { CancelRequestService, HelperService, SettingsService } from '@depot/@common';
import { FilterType, IPriceBookSearch, PriceBookRepositoryService } from '@depot/@data';
import { PriceBookLineGridDataSource } from '@depot/price-book/line';

import { BehaviorSubject, map } from 'rxjs';

import { isMatch, unique } from 'underscore';

@Component({
  selector: 'depot-price-book-line-grid',
  templateUrl: './price-book-line-grid.component.html',
  styleUrls: ['./price-book-line-grid.component.scss']
})
export class PriceBookLineGridComponent implements OnInit, OnDestroy {

  public dataSource = new PriceBookLineGridDataSource(this.priceBookRep);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatExpansionPanel) searchPanel: MatExpansionPanel;

  public cachedFilters$ = new BehaviorSubject<IPriceBookSearch>({});
  public manufacturers$ = new BehaviorSubject<{ make: string; partLine: string }[]>([]);
  public makes$ = this.manufacturers$.pipe(map(x => unique(x.map(y => y.make)).sort()));
  public partLines$ = this.manufacturers$.pipe(map(x => unique(x.map(y => y.partLine)).sort()));
  public filterTypes = { 0: 'StartsWith', 1: 'Contains', 2: 'EndsWith', 3: 'Exact' };

  get defaultFilters(): IPriceBookSearch {
    return {
      number: '',
      line: '',
      make: '',
      fuzzySearch: false,
      selectedFilter: FilterType.StartsWith
    };
  }

  constructor(
    private priceBookRep: PriceBookRepositoryService,
    private helper: HelperService,
    private settingsService: SettingsService,
    private httpCancelService: CancelRequestService,
    private destroyRef: DestroyRef,

  ) {
    this.dataSource.isLoading$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(x => this.helper.IsGlobalSpinner$.set(x));
    this.helper.IsGlobalBar$.set(true);
  }
  // ngAfterViewInit(): void {
  //   this.loadData();
  // }
  ngOnInit() {

    this.dataSource.totalRows$.subscribe(rows => this.paginator.length = rows);

    this.settingsService.dashboardSettings<IPriceBookSearch>(this.dataSource.filterName).then(cachedData => {

      if (!cachedData) {
        cachedData = this.defaultFilters;
      }
      if (!isMatch(cachedData, this.defaultFilters)) {
        this.searchPanel.open();
      }
      cachedData.pageIndex = this.paginator.pageIndex;
      cachedData.takeCount = cachedData.takeCount > 0 ? cachedData.takeCount : this.paginator.pageSizeOptions[0];
      this.paginator.pageSize = cachedData.takeCount;
      this.paginator.pageIndex = cachedData.pageIndex;

      this.cachedFilters$.next(cachedData);
      this.loadData();
      this.priceBookRep.getAvailableMakes().subscribe(x => this.manufacturers$.next(x));
    });
  }

  public async loadData(e?: Event) {
    this.httpCancelService.cancelPendingRequests();
    if (e) {
      e.stopPropagation();
      this.paginator.pageIndex = 0;
    }
    let sortInput = '';
    if (this.sort.active && this.sort.direction) {
      sortInput = this.sort.active + ' ' + this.sort.direction;
    }
    const values = this.cachedFilters$.getValue();
    values.takeCount = this.paginator.pageSize;
    values.pageIndex = this.paginator.pageIndex;
    values.orderBy = sortInput;

    const cachedData = await this.settingsService.dashboardSettings<IPriceBookSearch>(this.dataSource.filterName, values);
    this.dataSource.loadData(cachedData);
    this.helper.IsGlobalBar$.set(false);

  }

  public resetFilters(e?: Event) {
    if (e) {
      e.stopPropagation();
    }
    this.cachedFilters$.next(this.defaultFilters);
    this.paginator.firstPage();
    this.loadData();
  }

  public sortFilters() {
    this.paginator.firstPage();
    this.loadData();

  }

  ngOnDestroy(): void {
    this.cachedFilters$.complete();
    // this.depotContext.close();
  }



}
