import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService, HelperService } from '@depot/@common';
import { PriceBookRepositoryService } from '@depot/@data';
import { IPriceBookLine, IPriceBookPage } from '@depot/custom';
import { environment } from '@env';

import { BehaviorSubject, catchError, finalize, fromEvent, of } from 'rxjs';

import { sortBy } from 'underscore';

@Component({
  selector: 'depot-price-book-line-view',
  templateUrl: './price-book-line-view.component.html',
  styleUrls: ['./price-book-line-view.component.scss']
})
export class PriceBookLineViewComponent implements OnInit {
  public priceBookItems$ = new BehaviorSubject<IPriceBookLine[]>(null);
  public priceBookPage$ = new BehaviorSubject<IPriceBookPage>(null);
  public columns = ['edit', 'make', 'lineType', 'manufacturerNumber', 'description', 'weightIbs', 'date', 'dealerPrice'];
  public imagePath = environment.get_endpoint('pricebook/page/');
  public fuzzySearch: boolean;
  public pdfId = '';
  public currentPage$ = new BehaviorSubject<number>(0);
  public currentMaxPage$ = new BehaviorSubject<number>(0);

  private partLine: string;
  private manufacturerNumber: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private priceBookRepo: PriceBookRepositoryService,
    private helper: HelperService,
    public router: Router,
    private location: Location,
    private destroyRef: DestroyRef,
  ) {
    this.helper.IsGlobalSpinner$.set(true);

  }

  ngOnInit() {
    const params = this.activatedRoute.snapshot.params;
    this.partLine = params['line'];
    this.manufacturerNumber = params['manufacturerNumber'];
    this.fuzzySearch = this.activatedRoute.snapshot?.queryParams?.fuzzySearch?.toLowerCase() === 'true';
    this.getData();

    fromEvent(document, 'keyup')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x: KeyboardEvent) => {
        x.preventDefault();
        if (x && (x.key === 'ArrowLeft' || x.key === 'ArrowDown')) {
          this.changePage(-1);
        } else if (x && (x.key === 'ArrowRight' || x.key === 'ArrowUp')) {
          this.changePage(1);
        }
      });

  }

  public getData() {



    this.priceBookRepo.getPriceBookLine(this.partLine, this.manufacturerNumber, this.fuzzySearch, false, false)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.helper.mapErrors(err, null, null, null);
          return of(null);
        }),
        finalize(() => this.helper.IsGlobalBar$.set(false))
      )
      .subscribe(priceBookLine => {
        if (priceBookLine === null) {
          this.helper.IsGlobalSpinner$.set(false);
          return;
        }
        this.priceBookRepo.getCurrentPriceBook(priceBookLine[0].lineType)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              this.helper.mapErrors(err, null, null, null);
              return of(null);
            }),
            finalize(() => this.helper.IsGlobalSpinner$.set(false))
          )
          .subscribe(priceBookPage => {
            const sortedData = sortBy(priceBookLine, x => x.date).reverse();
            if (priceBookPage) {
              for (let i = 0; i < sortedData.length; i++) {
                sortedData[i].isCurrent = sortedData[i].date === priceBookPage.date && sortedData[i].lineType === priceBookPage.lineType;
              }
              const currentPriceBookPage = sortedData.find(x => x.isCurrent === true)?.priceBookPagePartial;
              if (currentPriceBookPage) {

                this.loadPdf(currentPriceBookPage);
              }
              this.priceBookPage$.next(priceBookPage);
            }
            this.priceBookItems$.next(sortedData);
            if (sortedData.length >= 50) {
              this.helper.showMessage('Only showing 50 rows to limit excessive data.  If this needs to change contact Eric', 'warn');
            }
          });

      });

  }

  public changePage(increment: number) {
    if (!this.pdfId) {
      return;
    }
    const pageNumber = this.pdfId.split(' - ')[1].replace('.pdf', '').split('#')[0];
    let newNumber = Number(pageNumber) + increment;
    if (this.currentMaxPage$.getValue() < newNumber) {
      newNumber = this.currentMaxPage$.getValue();
    }
    if (newNumber < 1) {
      newNumber = 1;
    }
    this.currentPage$.next(newNumber);
    this.pdfId = this.pdfId.replace(pageNumber, newNumber.toString().padStart(4, '0'));
    // const page = Number(this.activatedRoute.snapshot.queryParams['page'] ?? 0);
    // this.router.navigate([], { queryParams: { page: page + increment } });
    // this.loadPdf();
  }

  public loadPdf(priceBookPage: IPriceBookPage) {
    if (!priceBookPage?.name) {
      this.pdfId = '';
      return;
    }
    this.priceBookRepo.getPriceBookPageMaxLine(priceBookPage.make, priceBookPage.date).subscribe(x => {
      this.currentMaxPage$.next(Number(x.maxPage));
    });
    this.currentPage$.next(Number(priceBookPage.pageNumber));
    this.pdfId = this.imagePath + priceBookPage.name + '#view=FitH&toolbar=0';

    return false;
  }

  public fuzzyChange() {
    // const url = this.router.url;
    let url = this.helper.removeQueryParam('fuzzySearch', this.router.url);
    url = this.helper.appendQueryParam('fuzzySearch', this.fuzzySearch.toString(), url);
    this.location.go(url);
    // this.router.navigate(
    //   [],
    //   {
    //     relativeTo: this.activatedRoute,
    //     queryParams: { fuzzySearch: this.fuzzySearch },
    //     skipLocationChange: true,
    //     replaceUrl: true,
    //     queryParamsHandling: 'merge', // remove to replace all query params by provided
    //   });
    this.helper.IsGlobalSpinner$.set(true);

    this.priceBookItems$.next(null);
    this.priceBookPage$.next(null);
    this.loadPdf(null);
    this.getData();


  }



}
