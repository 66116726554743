<div class="tw-flex tw-flex-col">
  <div class="tw-flex tw-flex-row"
       style="margin: 0 10px;">
    <h3 *ngIf="(priceBookItems$ | async) as priceBooks">

      Price History for {{priceBooks[0]?.make}} {{priceBooks[0]?.manufacturerNumberAlpha}}

    </h3>
    <div class="dp-flex-space"></div>
    <h3 *ngIf="(priceBookPage$ | async) as priceBook">

      Current Price Book is {{priceBook?.date | date:'yyyy-MM-dd'}}

    </h3>
  </div>



  <mat-table *ngIf="(priceBookItems$ | async) as priceBooks "
             [dataSource]="priceBooks"
             class="content mat-elevation-z8"
             style="max-height: 43%; overflow-y: scroll;">

    <ng-container matColumnDef="edit">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef></mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        <a *ngIf="(authService.user$ | async).roleList?.includes('PriceBookEditAdmin')"
           mat-icon-button
           matTooltip="Edit"
           [routerLink]="['/price-book/line/',row.id]"
           [state]="{priceBookLine: row}">
          <span class="fa fa-edit"></span>
        </a>
        <a mat-icon-button
           matTooltip="View Page"
           (click)="loadPdf(row.priceBookPagePartial)"
           *ngIf="row?.priceBookPagePartial?.name?.length > 0"
           target="_blank"
           href="{{imagePath}}{{row.priceBookPagePartial?.name}}#view=FitH">
          <!--  -->
          <span class="fa fa-file-alt"></span>
        </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="make">
      <mat-header-cell *matHeaderCellDef>Make</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.make}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="lineType">
      <mat-header-cell *matHeaderCellDef>Line Type</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.lineType}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="manufacturerNumber">
      <mat-header-cell *matHeaderCellDef>Manufacturer Number</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.manufacturerNumberAlpha}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.description}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="weightIbs">
      <mat-header-cell *matHeaderCellDef>Weight</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.weightIbs}}</mat-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="listPrice">
      <mat-header-cell *matHeaderCellDef>List Price</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.listPrice | currency}}</mat-cell>
    </ng-container> -->


    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.date | date:'yyyy-MM-dd' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="dealerPrice">
      <mat-header-cell *matHeaderCellDef>Dealer Net</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row"> {{row.dealerPrice | currency}}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns; sticky: false"
                    class="table-header-primary">
    </mat-header-row>

    <mat-row *matRowDef="let row; let i = index; columns: columns;"
             [style.font-weight]="row.isCurrent === true ? '900' : ''"
             [style.font-style]="row.id===0 ? 'italic':''">
    </mat-row>

    <div class="no-data-row"
         *matNoDataRow>
      No price books found
    </div>

  </mat-table>



  <iframe [src]="pdfId | safe:'url'"
          type="application/pdf"
          style="flex-grow: 1;"></iframe>




</div>

<div class="dp-top-fab-container">

  <button type="button"
          [disabled]="pdfId?.length === 0 || (currentPage$ | async) >= (currentMaxPage$ | async)"
          (click)="changePage(1)"
          mat-mini-fab
          matTooltip="Next Page"
          matTooltipPosition="below"
          color="accent">
    <span class="fa fa-arrow-right"></span>
  </button>
  <!--  -->
  <button type="button"
          [disabled]="pdfId?.length === 0 || (currentPage$ | async)<=1"
          (click)="changePage(-1)"
          mat-mini-fab
          matTooltip="Previous Page"
          matTooltipPosition="below"
          color="accent">
    <span class="fa fa-arrow-left"></span>
  </button>

  <div style="font-weight: 900; ">
    <mat-form-field>
      <input matInput
             type="number"
             [disabled]="pdfId?.length === 0"
             min="1"
             style="text-align: right;"
             (change)="changePage($event.target.value - currentPage$.getValue())"
             [max]="currentMaxPage$ | async"
             [ngModel]="currentPage$ | async" />
      <span matTextSuffix>/ {{currentMaxPage$ | async}}</span>

    </mat-form-field>

  </div>
  <div>
    <mat-form-field>
      <mat-label>Fuzzy Search</mat-label>
      <mat-select (selectionChange)="fuzzyChange()"
                  [(ngModel)]="fuzzySearch">
        <mat-option [value]="true">
          Yes
        </mat-option>
        <mat-option [value]="false">
          No
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
